import React, { FunctionComponent } from 'react';

import { AboutPageStyle } from './AboutPageStyle';
import Layout from '../../layouts/Layout';
import { Helmet } from 'react-helmet';

const AboutUs: FunctionComponent<{}> = () => (
  <Layout isFAQPage>
    <AboutPageStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About us</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="about-content">
          <div className="about-title ">About us</div>
          <div className="about-subtitle">TravelPix offers professional photography at iconic<br></br>locations in London. No need to plan, reserve or commit.</div>
          <div className="about-note">
            <p>
              The idea of TravelPix was born out of frustration with our holiday photos that weren’t doing our epic trips justice. Both of
              us are avid travellers and our photos were often mediocre or didn’t include both of us. Having to rely on the photo skills of
              strangers or a selfie stick just wasn’t good enough. TravelPix fills that gap.
            </p>
            <p>Our mission is simple: We want to offer everyone great professional photos at epic locations for a fair price.</p>
            <p>
              Capturing memories with family &amp; friends is a big part of our holidays, and we think it should be easy to get great photos.
            </p>
            <p>
              Our team of motivated professional photographers are ready to capture your best side at landmarks all over London. No need to
              plan, reserve or commit. We are ready for you at the <span>best spots in the city</span>, know the best angles to capture the
              best memories of your trip.
            </p>
            <p>
              TravelPix launches in London in fall 2020 with a small (but growing) team focused entirely on improving our service and
              bringing it to more cities around Europe.
            </p>
          </div>

          <div>
            <div className="founder-title">The founders</div>
            <div className="pic-wrapper">
              <img className="pic" src="/about-founder.png" alt="founders" />
            </div>
            <div className="about-founders">
              <div className="founder-intro">
                <div className="founder-name">Raphael</div>
                <p>
                  I moved to London 15 years ago from Vienna, Austria. Ever since then it has been my home and I have loved it. London is
                  vibrant, full of energy, diverse & fun.
                </p>
                <p>
                  My background is in finance - trading bonds. I have always enjoyed photography, but it wasn’t until my wedding where I saw
                  that it is the skill & experience of a professional, not the quality of the camera, that really makes the photos stand
                  out.
                </p>
                <p>
                  Looking through my wedding photos, I saw the enormous difference. The directions our photographer gave Ali & I, the angles
                  she chose and the editing created beautiful photos and memories we will cherish forever.
                </p>
                <p>Our wedding was a special occasion, and so are the trips & holidays we all take.</p>
                <p>I am excited to offer great photos for your epic holidays and be a part of TravelPix.</p>
              </div>

              <div className="founder-intro">
                <div className="founder-name">Alexandra</div>
                <p>
                  I have lived in London for over 6 years, having moved here from Canada in 2014. Ever since, I have been exploring the
                  city; its royal architecture, the colourful and vibrant boroughs in east London and its many gorgeous parks. Going on
                  weekend breaks to numerous European cities made living in London even better.
                </p>

                <p>
                  Innovation has played a big role for me since day 1 of my career in startups and as a startup consultant. I have learnt
                  from some of the most brilliant people creating the future with new technologies and gotten a lot of experience in a
                  variety of industries.
                </p>

                <p>Two key lessons I have learnt are:</p>
                <p>1. Don't overcomplicate it, sometimes the simplest ideas are the best.</p>

                <p>2. Create something that people want, fill a gap.</p>

                <p>
                  TravelPix’s concept is simple and solving a challenge I have had my entire traveling life. I am so excited we can bring it
                  to you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutPageStyle>
  </Layout >
);

export default AboutUs;
