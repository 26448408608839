import styled from '@emotion/styled';

const AboutPageStyle = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 145px 0 93px;

  .content-wrapper {
    max-width: 917px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .about-content {
      width: 661px;
      margin-left: -28%;

      .about-title {
        font-size: 60px;
        line-height: 64px;
        color: #fbca00;
        font-family: RubikMedium;
        padding-bottom: 31px;
      }
      .about-subtitle {
        font-size: 20px;
        line-height: 24px;
        color: #1d2332;
        font-family: RubikMedium;
      }
      .about-note {
        padding-right: 30px;
      }
      p {
        font-size: 15px;
        line-height: 20px;
        color: #a5a7ad;
        font-family: RubikRegular;
        span {
          color: #fbca00;
          font-family: RubikBold;
        }
      }

      .founder-title {
        margin-top: 30px;
        font-size: 20px;
        line-height: 24px;
        color: #1d2332;
        font-family: RubikMedium;
        margin-bottom: 12px;
      }

      .founder-name {
        margin-top: 19px;
        font-size: 16px;
        line-height: 19px;
        color: #1d2332;
        font-family: RubikMedium;
        margin-bottom: 10px;
      }

      .pic-wrapper {
        width: 661px;

        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .pic {
          width: 100%;
          height: auto;
        }
      }

      .about-founders {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .founder-intro {
          width: 50%;
          &:first-of-type {
            padding-right: 40px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1120px) {
    .content-wrapper {
      .about-content {
        margin-left: 0%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content-wrapper {
      justify-content: flex-start;
      align-items: flex-start;

      .about-content {
        width: 100%;
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 521px;

        .about-title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .about-note {
          padding-right: 0px;
        }

        .pic-wrapper {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .content-wrapper {
      .about-content {
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;

        .about-title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .about-note {
          padding-right: 0px;
        }

        .pic-wrapper {
          width: 100%;
        }

        .about-founders {
          flex-direction: column;
          .founder-intro {
            width: 100%;
            &:first-of-type {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
`;

export { AboutPageStyle };
